import { jsx as _jsx } from "react/jsx-runtime";
import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';
// ----------------------------------------------------------------------
const Loadable = (Component) => (props) => (_jsx(Suspense, { fallback: _jsx(LoadingScreen, {}), children: _jsx(Component, { ...props }) }));
// ----------------------------------------------------------------------
export const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/ResetPassword')));
export const ConfirmAccountMailPage = Loadable(lazy(() => import('../pages/ConfirmAccountMail')));
export const Permissions = Loadable(lazy(() => import('../pages/Permissions')));
export const BranchCreate = Loadable(lazy(() => import('../pages/Branches/BranchCreatePage')));
export const BranchDetails = Loadable(lazy(() => import('../pages/Branches/BranchDetailsPage')));
export const BranchEdit = Loadable(lazy(() => import('../pages/Branches/BranchEditPage')));
export const BranchList = Loadable(lazy(() => import('../pages/Branches/BranchListPage')));
export const Classes = Loadable(lazy(() => import('../pages/Classes/ClassListPage')));
export const ClassCreatePage = Loadable(lazy(() => import('../pages/Classes/ClassCreatePage')));
export const ClassEditPage = Loadable(lazy(() => import('../pages/Classes/ClassEditPage')));
export const ClassDetailsPage = Loadable(lazy(() => import('../pages/Classes/ClassesDetailPage')));
export const Register = Loadable(lazy(() => import('../pages/Register')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/VerifyCodePage')));
export const ExpenseConfigCreate = Loadable(lazy(() => import('../pages/Settings/Expense/ExpenseConfigCreatePage')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const ExpenseConfigEdit = Loadable(lazy(() => import('../pages/Settings/Expense/ExpenseConfigEditPage')));
export const ExpenseConfigList = Loadable(lazy(() => import('../pages/Settings/Expense/ExpenseConfigListPage')));
export const PricingGroupCreate = Loadable(lazy(() => import('../pages/PricingGroup/PricingGroupCreatePage')));
export const PricingGroupDetails = Loadable(lazy(() => import('../pages/PricingGroup/PricingGroupDetailsPage')));
export const PricingGroupEdit = Loadable(lazy(() => import('../pages/PricingGroup/PricingGroupEditPage')));
export const PricingGroupList = Loadable(lazy(() => import('../pages/PricingGroup/PricingGroupsListPage')));
export const ReservationDetails = Loadable(lazy(() => import('../pages/Reservation/ReservationDetailsPage')));
export const ReservationList = Loadable(lazy(() => import('../pages/Reservation/ReservationListPage')));
export const ReservationRejectedList = Loadable(lazy(() => import('../pages/Reservation/ReservationRejectedListPage')));
export const ReservationContract = Loadable(lazy(() => import('../pages/Reservation/ReservationContractPage')));
export const CreateReservation = Loadable(lazy(() => import('../pages/Reservation/CreateReservation')));
export const ReservationEditPage = Loadable(lazy(() => import('../pages/Reservation/ReservationEditPage')));
export const CustomerList = Loadable(lazy(() => import('../pages/Customers/CustomerListPage')));
export const CustomerDetails = Loadable(lazy(() => import('../pages/Customers/CustomerDetailsPage')));
export const BranchCashboxList = Loadable(lazy(() => import('../pages/BranchCashbox/BranchCashboxListPage')));
export const BranchCashboxCreate = Loadable(lazy(() => import('../pages/BranchCashbox/BranchCashboxCreatePage')));
export const BranchCashboxEdit = Loadable(lazy(() => import('../pages/BranchCashbox/BranchCashboxEditPage')));
export const BranchCashboxDetails = Loadable(lazy(() => import('../pages/BranchCashbox/BranchCashboxDetailsPage')));
export const BankAccountList = Loadable(lazy(() => import('../pages/BankAccount/BankAccountListPage')));
export const BankAccountCreate = Loadable(lazy(() => import('../pages/BankAccount/BankAccountCreatePage')));
export const BankAccountEdit = Loadable(lazy(() => import('../pages/BankAccount/BankAccountEditPage')));
export const BankAccountDetails = Loadable(lazy(() => import('../pages/BankAccount/BankAccountDetailsPage')));
export const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));
export const Rentals = Loadable(lazy(() => import('../pages/Rentals/RentalListPage')));
export const ExpenseCreate = Loadable(lazy(() => import('../pages/Expenses/ExpenseCreatePage')));
export const ExpenseDetails = Loadable(lazy(() => import('../pages/Expenses/ExpenseDetailsPage')));
export const ExpenseList = Loadable(lazy(() => import('../pages/Expenses/ExpenseListPage')));
export const ExpenseEdit = Loadable(lazy(() => import('../pages/Expenses/ExpenseEditPage')));
export const InvoiceList = Loadable(lazy(() => import('../pages/Invoices/InvoiceListPage')));
export const InvoiceCreate = Loadable(lazy(() => import('../pages/Invoices/InvoiceCreatePage')));
export const InvoiceEdit = Loadable(lazy(() => import('../pages/Invoices/InvoiceEditPage')));
export const InvoiceView = Loadable(lazy(() => import('../pages/Invoices/InvoiceDetailsPage')));
export const Seasons = Loadable(lazy(() => import('../pages/Seasons/SeasonListPage')));
export const HomePage = Loadable(lazy(() => import('../pages/HomePage')));
export const Calender = Loadable(lazy(() => import('../pages/Reservation/Calender')));
export const SeasonCreate = Loadable(lazy(() => import('../pages/Seasons/SeasonCreatePage')));
export const SeasonsDetailPage = Loadable(lazy(() => import('../pages/Seasons/SeasonsDetailPage')));
export const SeasonEditPage = Loadable(lazy(() => import('../pages/Seasons/SeasonEditPage')));
export const CreateAgencyPage = Loadable(lazy(() => import('../pages/Settings/Agency/AgencyCreatePage')));
export const EditAgencyPage = Loadable(lazy(() => import('../pages/Settings/Agency/AgencyEditPage')));
export const ShowAgencyPage = Loadable(lazy(() => import('../pages/Settings/Agency/AgencyShowPage')));
export const UsersList = Loadable(lazy(() => import('../pages/Settings/User/UserListPage')));
export const EditUsersPage = Loadable(lazy(() => import('../pages/Settings/User/UserEditPage')));
export const CreateUserPage = Loadable(lazy(() => import('../pages/Settings/User/UserCreatePage')));
export const UsersDetailPage = Loadable(lazy(() => import('../pages/Settings/User/UsersDetailPage')));
export const RentalCreatePage = Loadable(lazy(() => import('../pages/Rentals/RentalCreatePage')));
export const RentalDetailsPage = Loadable(lazy(() => import('../pages/Rentals/RentalDetailsPage')));
export const RentalEditPage = Loadable(lazy(() => import('../pages/Rentals/RentalEditPage')));
export const AgenciesList = Loadable(lazy(() => import('../pages/Settings/Agency/AgencyListPage')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
